import { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, EffectFade, Lazy } from "swiper";
import SlideButton from "./Slide-Button";
import { Button, Modal } from "react-bootstrap";
import axios from "axios";

import "swiper/css";
import "swiper/css/lazy";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import "swiper/css/autoplay";
import { Arrowrightw } from "../../icons";
import { SPEEDSIZE_PREFIX } from "../../App";

function MySwiper({ device }) {
  // const BROADCAST_DATE = "2022-10-30T19:00:00Z";
  // const DATE_TO_COUNT = "2022-12-18T15:00:00Z";
  // const [showTimer, setShowTimer] = useState(false);

  const location = window.location.host;
  // console.log(location);

  const [imgs, setImgs] = useState([]);
  const [liveBanners, setLiveBanners] = useState([]);
  const [swiper, setSwiper] = useState(null);
  const [ctaText, setCtaText] = useState("Regístrate");
  const [ctaLink, setCtaLink] = useState(
    "https://m.apuestas.codere.es/deportesEs/#/mso/RegistroNewPage"
  );
  const [currentTycType, setCurrentTycType] = useState(null);
  const [currentBannerName, setCurrentBannerName] = useState("");
  const [showTycModal, setShowTycModal] = useState(false);

  // Helpers
  // const [showTyc1, setShowTyc1] = useState(false);
  // const [showTyc2, setShowTyc2] = useState(false);

  const modalGenerator = () => {
    return (
      <>
        <Modal
          centered
          show={showTycModal}
          onHide={() => setShowTycModal(false)}
          contentClassName="terms-tyc"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <div className="test">
                <h5>Términos y condiciones</h5>
                <h5>{currentBannerName}</h5>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="m-auto">
            {currentTycType && tycGenerator(currentTycType)}
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => setShowTycModal(false)}
              className="accept-btn"
            >
              Aceptar
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };
  const tycGenerator = (type) => {
    return (
      <iframe
        width="100%"
        height="400px"
        src={
          location.includes("codere.es")
            ? `https://www.codere.es/assets1/tyc/es-tyc-${type}.html` // for prd env
            : `https://blog.codere.es/terminos-y-condiciones/${type}` // for testing env
        }
      />
    );
  };

  const indexHandler = (swiper) => {
    setSwiper(swiper);
    try {
      setCtaText(liveBanners[swiper.realIndex].ctaText);
      setCtaLink(liveBanners[swiper.realIndex].ctaURL);
      setCurrentTycType(liveBanners[swiper.realIndex].tycType || null);
      setCurrentBannerName(liveBanners[swiper.realIndex].tycTitle || "");
    } catch (error) {}
  };

  useEffect(() => {
    if (swiper) {
      if (showTycModal) swiper.autoplay.pause();
    }
  }, [showTycModal]);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_SERVER || "http://localhost:5001"}/prd/spain`,
        { headers: JSON.parse(process.env.REACT_APP_SER_HEADER) }
      )
      .then((res) => {
        let x = res.data[0].slide_list.filter(BannerFilter);
        setImgs([...x]);

        setLiveBanners(
          x.reduce((acc, obj) => {
            const tempBanner = {
              ...obj,
              image: obj.image.replace("xxx", device ? "mobile" : "desktop"),
            };
            acc.push(tempBanner);
            return acc;
          }, [])
        );
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    let tempList = imgs.reduce((acc, obj) => {
      const tempBanner = {
        ...obj,
        image: obj.image.replace("xxx", device ? "mobile" : "desktop"),
      };
      acc.push(tempBanner);
      return acc;
    }, []);

    setLiveBanners([...tempList]);
  }, [device]);

  function dateInBetween(banner) {
    return (
      new Date() >= new Date(banner.startDate) &&
      new Date() <= new Date(banner.endDate)
    );
  }
  function BannerFilter(banner) {
    if (!banner.scheduled) return banner;
    else {
      if (dateInBetween(banner)) return banner;
    }
  }

  return (
    <div id="carousel-section">
      {liveBanners.length > 0 && (
        <>
          <Swiper
            onSlideChange={(swiper) => indexHandler(swiper)}
            modules={[Pagination, EffectFade, Autoplay, Lazy]}
            pagination={{ clickable: true }}
            effect={"fade"}
            autoplay={{ delay: 3250, disableOnInteraction: false }}
            spaceBetween={0}
            slidesPerView={1}
            loop={true}
            init={false}
          >
            {liveBanners.map((item, index) => (
              <SwiperSlide key={index}>
                <a href={ctaLink}>
                  <img
                    width={device ? "300" : "400"}
                    height={device ? "410" : "140"}
                    src={`${SPEEDSIZE_PREFIX}/${item.image}`}
                    alt={item.img_alt}
                  />
                </a>
              </SwiperSlide>
            ))}
          </Swiper>

          {!device ? (
            <div id="des-reg">
              <Button
                className="central-regis _gl-effect"
                href={ctaLink}
                rel="nofollow"
              >
                {ctaText}
                <Arrowrightw />
              </Button>
              {currentTycType && (
                <span className="tyc" onClick={() => setShowTycModal(true)}>
                  Términos y condiciones
                </span>
              )}
            </div>
          ) : (
            <div className="ReactSwipeButton mobile_regis">
              <SlideButton regis={ctaLink} />
              {currentTycType && (
                <div className="mob-tyc">
                  <span className="" onClick={() => setShowTycModal(true)}>
                    Términos y condiciones
                  </span>
                </div>
              )}
            </div>
          )}

          {modalGenerator()}
        </>
      )}
    </div>
  );
}
export default MySwiper;
