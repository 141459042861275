import { Zoom } from "react-awesome-reveal";
import { Accordion, NavLink, Table } from "react-bootstrap";

var questions = [
  "¿En qué consisten las apuestas deportivas?",
  "¿Cuáles son las apuestas más comunes?",
  "¿Cómo empezar a hacer apuestas online?",
  "¿Las apuestas deportivas online son seguras?",
];
var answers = [
  "Las apuestas deportivas son una modalidad de juego en la que los participantes pronostican resultados de eventos deportivos y realizan apuestas basadas en esas predicciones. Los apostantes pueden apostar en diversos resultados, como el desenlace final del encuentro, el marcador exacto, el desempeño de un jugador, entre otros. Este tipo de apuestas se pueden llevar a cabo en eventos deportivos como fútbol, baloncesto, tenis, futbol americano, y muchos mas.",
  "Las apuestas sencillas son una opción popular, donde los apostadores seleccionan un único resultado en un evento deportivo, como el ganador de un partido. Por otro lado, las apuestas en directo ofrecen una experiencia dinámica al permitir a los apostadores realizar pronósticos mientras el partido está en curso, ajustando sus decisiones según el desarrollo del juego. ",
  "Es fundamental familiarizarte con los conceptos básicos de las apuestas deportivas, comprendiendo los diferentes tipos de apuestas disponibles y cómo funcionan las cuotas. Luego, dedica tiempo a investigar sobre los equipos y jugadores, incluyendo su desempeño reciente, posibles lesiones y estrategias de juego.Es crucial establecer un presupuesto de apuestas y seguir una estrategia sólida de gestión del bankroll para evitar pérdidas significativas. Comienza con apuestas pequeñas y aumenta gradualmente tu nivel de experiencia a medida que ganas confianza en tus habilidades de análisis y pronóstico.",
  "Las apuestas deportivas en línea son seguras si se realizan en plataformas legales y reguladas, como Codere. En Codere seleccionamos plataformas fiables y reconocidas, que emplean tecnologías de cifrado seguras para proteger las transacciones financieras y los datos del usuario.",
];

function Content() {
  const prefix = "https://www.codere.es";

  return (
    <div className="content-container container text-white">
      <h1 className="text-center">
        Tu Casa Líder de Apuestas Deportivas en España
      </h1>
      <p className="w-75 m-auto mb-3">
        ¡Bienvenido a Codere.es, el mejor sitio para apuestas deportivas online!
        Si te apasionan los deportes, este es tu lugar. Ofrecemos mercados
        exclusivos y cuotas competitivas para eventos como LaLiga, la Champions
        League, la F1, el tenis, la UFC y mucho más. Disfruta de las apuestas en
        directo y lleva tu emoción al siguiente nivel.
      </p>

      <div className="row justify-content-evenly mt-4">
        <div className="col-12 col-sm-4">
          <Zoom triggerOnce>
            <div>
              <h2>Juega Seguro con la Tecnología de Codere </h2>
              <p>
                En Codere, tu seguridad y tranquilidad son nuestra prioridad.
                Utilizamos tecnología avanzada para proteger tus datos
                personales y ofrecerte un entorno regulado. Aquí puedes
                disfrutar de tus apuestas deportivas con total confianza.
                Nuestro compromiso es brindarte una experiencia de juego segura,
                transparente y responsable.
              </p>
            </div>
            <div>
              <h2>
                Explora Todas las Opciones para Apostar en tus Deportes
                Favoritos
              </h2>
              <p>
                Codere es tu mejor elección entre las casas de apuestas en
                España. Descubre todas las formas de apostar en tus deportes
                favoritos, desde fútbol y tenis hasta los eventos más
                emocionantes de esports.
              </p>
              <ul>
                <li>
                  <u>Apuestas 1X2: </u>Predice el ganador o el empate en
                  partidos como El Clásico. ¿Ganará el Real Madrid (1), habrá
                  empate (X) o se llevará la victoria el Barça (2)? ¡Tú decides!
                </li>
                <li>
                  <u>Doble oportunidad: </u>Predice el ganador o el empate en
                  partidos como El Clásico. ¿Ganará el Real Madrid (1), habrá
                  empate (X) o se llevará la victoria el Barça (2)? ¡Tú decides!
                </li>
              </ul>
            </div>
          </Zoom>
        </div>
        <div className="col-12 col-sm-4">
          <Zoom triggerOnce>
            <ul>
              <li>
                <u>Hándicap europeo y asiático</u>: Añade estrategia y emoción.
                Si el Real Madrid juega con un hándicap -1, tu apuesta será
                ganadora solo si ganan por al menos dos goles. Con el hándicap
                asiático, si el resultado coincide con el margen elegido, te
                devolvemos el dinero. ¡Más formas de ganar en cada partido!
              </li>
              <li>
                <u>Marcador exacto: </u>Pon a prueba tu intuición prediciendo el
                resultado exacto. Por ejemplo, en un duelo de LaLiga entre
                Atlético de Madrid y Sevilla, apuesta por un marcador exacto
                como 2-1 a favor del Atlético. ¡Si aciertas, la recompensa será
                mucho mayor!
              </li>
              <li>
                <u>Primer goleador: </u>Apuesta al primer jugador en marcar en
                un partido. Ideal para eventos exclusivos como la Champions
                League. Imagina un duelo entre los mejores equipos de Europa y
                apuesta por quién marcará el primer gol. ¡Vive la emoción desde
                el primer minuto!
              </li>
              <li>
                <u>Marcan ambos equipos: </u>Apuesta por partidos en los que
                ambos equipos tienen grandes opciones de marcar. Ideal para
                enfrentamientos de alta intensidad como un Sevilla vs.
                Villarreal. ¡Emoción garantizada y grandes oportunidades de
                éxito!
              </li>
            </ul>
            <p>
              Explora todas estas opciones y lleva tus apuestas deportivas al
              siguiente nivel con Codere.
            </p>
          </Zoom>
        </div>
        <div className="col-12 col-sm-4">
          <Zoom triggerOnce>
            <aside>
              <h2>
                Más que una Apuesta: Disfruta del Mejor Casino Online en España
              </h2>
              <p>
                Codere no solo es líder en apuestas deportivas, también te
                ofrece el casino online más completo y emocionante de España.
              </p>
              <h3>Lo que encontrarás en nuestro Casino Online </h3>
              <ul>
                <li>
                  <a href="https://www.codere.es/casino/ruleta">
                    Ruleta en vivo
                  </a>
                  : Juega con crupieres reales y disfruta de una experiencia
                  auténtica desde casa.
                </li>
                <li>
                  <a href="https://www.codere.es/casino/slots">Slots premium</a>
                  : Las tragaperras más populares con premios irresistibles.
                </li>
                <li>
                  <a href="https://www.codere.es/casino/blackjack">
                    Blackjack online
                  </a>
                  : Enfréntate a la banca en nuestras mesas de 21 blackjack
                </li>
                <li>
                  <a href="https://www.codere.es/casino/casino-en-vivo">
                    Casino Live
                  </a>
                  : Vive la adrenalina con transmisiones de alta calidad, juegos
                  y crupieres reales
                </li>
                <li>
                  <a href="https://www.codere.es/casino">Casino Online</a>:
                  Codere te garantiza una experiencia inigualable, con gráficos
                  avanzados y una interfaz intuitiva
                </li>
              </ul>
            </aside>
            <div>
              <h2>Aprovecha tu Bono de Bienvenida de 200€ </h2>

              <p>
                ¿Listo para empezar? Regístrate ahora en Codere.es y RECIBE el
                100% de tu primer depósito, previa inscripción en la promoción,
                hasta un máximo de 200€, divididos en 50% de bono de casino y
                50% de freebet.
              </p>
            </div>
          </Zoom>
        </div>
      </div>
      <Table striped bordered hover variant="dark" className="mb-4">
        <tbody>
          <tr>
            <td>Inicio de operaciones</td>
            <td>2008</td>
          </tr>
          <tr>
            <td>Apuestas Deportivas</td>
            <td>Apuestas fútbol, Apuestas tenis, Apuestas baloncesto y más</td>
          </tr>
          <tr>
            <td>Licencia</td>
            <td>
              <span className="text-break-all">
                Licencias otorgadas por la DGOJ: 225-11/GA/A86346038/SGR,
                AOC/2014/002, ADC/2014/001, AHC/2014/002, 223-11/GO/
                A86346038/SGR,MAZ/2015/032,RLT/2016/009, BLJ/2016/007.
              </span>
            </td>
          </tr>
          <tr>
            <td>Moneda</td>
            <td>EUR</td>
          </tr>
          <tr>
            <td>Depósito mínimo</td>
            <td>10€</td>
          </tr>
        </tbody>
      </Table>

      <section>
        <h2>FAQ’S apuestas deportivas</h2>
        <Accordion className="content-accordion">
          {answers.map((answer, index) => {
            return (
              <Accordion.Item key={index} eventKey={index}>
                <Accordion.Header as="h3">{questions[index]}</Accordion.Header>
                <Accordion.Body>
                  <p>{answer}</p>
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
        </Accordion>
      </section>
    </div>
  );
}

export default Content;
